import styled from 'styled-components';

export const AutoCompleteInputWrapper = styled.div`
  height: 40px;
  border-radius: ${({ theme }) => theme.space[2]}px;
  outline: none;
  border: solid 1px ${props => props.theme.color.border};
  background-color: ${({ theme }) => theme.color.white};
  padding: 0 ${({ theme }) => theme.space[4]}px;
  box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(27, 32, 50, 0.04)
  display: flex;
  position: absolute;
  top: ${({ theme }) => theme.space[4]}px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;

  & input {
    background: transparent;
    width: 100%;
    border: none;
    padding: ${({ theme }) => theme.space[2]}px;
    // The minimum font size required by Safari to stop auto-zooming
    font-size: ${({ theme }) => theme.fontSizes.body1};
    &:focus {
      outline: none;
    }
  }
`;

export const StyledAreaList = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]}px;
  cursor: pointer;
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[2]}px;
  border-radius: ${({ theme }) => theme.space[2]}px;
  &:hover {
    background-color: ${({ theme }) => theme.color.grey1};
  }
`;

export const StyledAction = styled.div`
  padding: ${({ theme }) => theme.space[4]}px;
`;

export const StyledSuggestedAreasWrapper = styled.div`
  background-color: white;
  position: absolute;
  border-radius: ${({ theme }) => theme.space[2]}px;
  border: solid 1px ${props => props.theme.color.border};
  width: 100%;
  max-height: 210px;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[2]}px;
  right: 0;
`;

export const StyledEsriMapLayerDiv = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledTitleSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.body2};
`;

export const StyledDetailsSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.caption};
`;

export const StyledLabelWrapper = styled.div`
  font-size: 0.8rem;
  color: #333;
  margin-bottom: ${({ searchData }) => (searchData?.length > 0 ? '5px' : '0')};
  background-color: #f5f5f5;
`;

export const StyledLabel = styled.label`
  padding: 6px;
  font-size: 11px;
`;

export const StyledHr = styled.hr`
  margin: 0;
  border: none;
  border-bottom: 1px solid #ccc;
`;

export const ZoomControlContainer = styled.div`
  position: absolute;
  top: 80px;
  right: 20px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const ZoomControlButton = styled.button`
  border: none;
  background: none;
  padding: 8px;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
