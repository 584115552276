import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { debounce } from 'common/utils';
import { CoordsType } from 'common/types';
import { DEBOUNCE_RATE, LANGUAGE_AR } from 'common/constants';
import MapEsriLayer from './MapEsriLayer';
import {
  AutoCompleteInputWrapper,
  StyledAreaList,
  StyledDetailsSpan,
  StyledHr,
  StyledLabel,
  StyledLabelWrapper,
  StyledSuggestedAreasWrapper,
  StyledTitleSpan,
} from './style';
import { autoSearchPaci } from './utils';

type PaciMapPropTypes = {
  center: CoordsType;
  zoom: number;
  onDragEndOrAreaSelect(coords: CoordsType): any;
};

const PaciMap: React.FC<PaciMapPropTypes> = ({ center, zoom, onDragEndOrAreaSelect }) => {
  const [zoomLvl, setZoomLvl] = useState(zoom || 16);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const [searchData, setSearchData] = useState(null);
  const [searchLang, setSearchLang] = useState(LANGUAGE_AR);
  const [isSearching, setIsSearching] = useState(false);
  const { t } = useTranslation('address');

  const handleInputFocus = () => {
    setIsSearchOpen(true);
  };

  const handleOnInputChange = (value: string) => {
    setSearchValue(value);
    debounce(async () => {
      await autoSearchPaci(value).then((res: any) => {
        const { result, resultLanguage } = res;
        setSearchLang(resultLanguage);
        setSearchData(result);
      });
    }, DEBOUNCE_RATE);
  };

  const handleOnSelectArea = area => {
    setIsSearchOpen(false);
    setIsSearching(false);
    const coords = { lng: area.X, lat: area.Y };
    onDragEndOrAreaSelect(coords);
    setZoomLvl(16);
  };

  return (
    <>
      <MapEsriLayer lat={center.lat} lng={center.lng} zoomLvl={zoomLvl} onDragEndOrAreaSelect={onDragEndOrAreaSelect} />
      <AutoCompleteInputWrapper
        tabIndex={0}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          const { currentTarget } = event;
          // Give browser time to focus the next element
          requestAnimationFrame(() => {
            // Check if the new focused element is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
              setIsSearching(false);
            }
          });
        }}
      >
        <input
          type="text"
          id="addressInput"
          placeholder={t('searchPaci')}
          onChange={(e: any) => handleOnInputChange(e.target.value)}
          onClick={() => setIsSearching(true)}
          value={searchValue}
          onFocus={handleInputFocus}
        />
        {isSearchOpen && searchData?.length > 0 && isSearching && (
          <StyledSuggestedAreasWrapper>
            {searchValue && (
              <StyledLabelWrapper searchData={searchData}>
                <StyledLabel htmlFor="addressInput">{t('address:paciSearchLabel')}</StyledLabel>
                <StyledHr />
              </StyledLabelWrapper>
            )}
            <ul>
              {searchData.map(
                (area: any) =>
                  area.X && (
                    <StyledAreaList key={`${area.ID}-styled`} onClick={() => handleOnSelectArea(area)}>
                      <StyledTitleSpan>{searchLang === LANGUAGE_AR ? area.TitleArabic : area.TitleEnglish}</StyledTitleSpan>
                      <StyledDetailsSpan>
                        {searchLang === LANGUAGE_AR ? area.DetailsArabic : area.DetailsEnglish}
                      </StyledDetailsSpan>
                    </StyledAreaList>
                  ),
              )}
            </ul>
          </StyledSuggestedAreasWrapper>
        )}
      </AutoCompleteInputWrapper>
    </>
  );
};

export default PaciMap;
