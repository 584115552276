import React, { useEffect, useRef, useState } from 'react';
import { loadModules } from 'esri-loader';
import { CoordsType } from 'common/types';
import dynamic from 'next/dynamic';
import { logger } from 'common/utils';
import { StyledEsriMapLayerDiv, ZoomControlButton, ZoomControlContainer } from './style';

type MapEsriLayerPropTypes = {
  lat: number;
  lng: number;
  zoomLvl: number;
  onDragEndOrAreaSelect(coords: CoordsType): any;
};

const MapEsriLayer: React.FC<MapEsriLayerPropTypes> = ({ lat, lng, zoomLvl, onDragEndOrAreaSelect }) => {
  const mapViewRef = useRef(null);
  const viewRef = useRef(null);
  const centerPointGraphicRef = useRef(null);
  const selectedLocationPointGraphicRef = useRef(null);
  const [isFirstLocationChange, setIsFirstLocationChange] = useState(true);

  useEffect(() => {
    const loadMap = async () => {
      try {
        if (mapViewRef.current) {
          const [Map, MapView, VectorTileLayer, Point, Graphic] = await loadModules([
            'esri/Map',
            'esri/views/MapView',
            'esri/layers/VectorTileLayer',
            'esri/geometry/Point',
            'esri/Graphic',
          ]);

          const initializeMap = () => {
            const map = new Map();
            const view = new MapView({
              map,
              ui: { components: [] },
              container: mapViewRef.current,
              center: [lng, lat],
              zoom: zoomLvl,
            });

            viewRef.current = view;
            return { map, view };
          };

          const addLayers = map => {
            const basemap = new VectorTileLayer({
              url: 'https://paci.maps.arcgis.com/sharing/rest/content/items/97ec0a48672a43f6a026dce96c91b16c/resources/styles/root.json?f=pjson',
            });
            map.add(basemap);

            const label = new VectorTileLayer({
              url: 'https://paci.maps.arcgis.com/sharing/rest/content/items/65f3742a8b7844c48e42ce7f113ad98a/resources/styles/root.json?f=pjson',
            });
            map.add(label);
          };

          const createGraphics = view => {
            const centerPoint = new Point({
              latitude: lat,
              longitude: lng,
              spatialReference: view.spatialReference,
            });

            const selectedLocationPoint = new Point({
              latitude: lat,
              longitude: lng,
              spatialReference: view.spatialReference,
            });

            const centerPointGraphic = new Graphic({
              geometry: centerPoint,
              symbol: { type: 'simple-marker', color: 'blue', size: '10px', outline: { color: 'white', width: 0.8 } },
            });

            const selectedLocationPointGraphic = new Graphic({
              geometry: selectedLocationPoint,
              symbol: {
                type: 'picture-marker',
                url: '/map-marker.svg',
                width: '24px', // Adjust the width as needed
                height: '24px', // Adjust the height as needed
              },
            });

            view.graphics.add(centerPointGraphic);

            centerPointGraphicRef.current = centerPointGraphic;
            selectedLocationPointGraphicRef.current = selectedLocationPointGraphic;
          };

          const handleViewEvents = view => {
            view.watch('center', newValue => {
              centerPointGraphicRef.current.geometry = newValue;
            });

            view.on('drag', event => {
              if (event.action === 'end') {
                const coords = {
                  lat: centerPointGraphicRef.current.geometry.latitude,
                  lng: centerPointGraphicRef.current.geometry.longitude,
                };
                onDragEndOrAreaSelect(coords);
                selectedLocationPointGraphicRef.current.geometry = centerPointGraphicRef.current.geometry;
              }
            });

            view.on('click', event => {
              const coords = {
                lat: event.mapPoint.latitude,
                lng: event.mapPoint.longitude,
              };
              onDragEndOrAreaSelect(coords);
              selectedLocationPointGraphicRef.current.geometry = event.mapPoint;
            });
          };

          const { map, view } = initializeMap();
          addLayers(map);
          createGraphics(view);
          handleViewEvents(view);
        }
      } catch (error) {
        logger.error({
          error: 'PACI error, Error loading ArcGIS API for JavaScript',
          extraData: error,
        });
      }
    };
    loadMap();
  }, []);

  useEffect(() => {
    if (viewRef.current && centerPointGraphicRef.current) {
      viewRef.current
        .goTo(
          {
            center: [lng, lat],
            zoom: viewRef.current.zoom < 12 && zoomLvl < 16 ? 12 : Math.max(viewRef.current.zoom, zoomLvl),
          },
          { duration: 700 },
        )
        .catch(error => {
          if (error.name !== 'AbortError') {
            logger.error({
              error: 'PACI error, Error updating the map view with ArcGIS API for JavaScript',
              extraData: error,
            });
          }
        });

      centerPointGraphicRef.current.geometry.longitude = lng;
      centerPointGraphicRef.current.geometry.latitude = lat;

      selectedLocationPointGraphicRef.current.geometry = centerPointGraphicRef.current.geometry;

      if (isFirstLocationChange) {
        viewRef.current.graphics.add(selectedLocationPointGraphicRef.current);
        setIsFirstLocationChange(false);
      }
    }
  }, [lat, lng]);

  const handleZoomChange = number => {
    if (viewRef.current) viewRef.current.zoom += number;
  };

  return (
    <>
      <StyledEsriMapLayerDiv ref={mapViewRef} />
      <ZoomControlContainer>
        <ZoomControlButton onClick={() => handleZoomChange(0.6)}>+</ZoomControlButton>
        <ZoomControlButton onClick={() => handleZoomChange(-0.6)}>-</ZoomControlButton>
      </ZoomControlContainer>
    </>
  );
};

export default dynamic(() => Promise.resolve(MapEsriLayer), {
  ssr: false,
});
